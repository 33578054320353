import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";

import Recharge from '../Recharge';
import MobilePay from '../mobile/MobilePay';
import LandlinePay from "../landline/LandlinePay";
import ElectricityPay from "../electricity/ElectricityPay";
import GasPay from "../gas/GasPay";
import BroadbandPay from "../broadband/BroadbandPay";
import DthPay from "../dth/DthPay";
import DatacardPay from "../datacard/DatacardPay";
import WaterPay from "../water/WaterPay";
import MaintenanceMode from '../core/components/Maintenance'
import config from '../core/Config'

export default class Root extends Component{
    renderRoutes = () =>{
        if(config.IS_MAINTENANCE_MODE){
            return(
                <MaintenanceMode />
            );
        }
        else{
            return(
                <Switch>

                    <PrivateRoute exact path="/" component={MobilePay} />

                    <PrivateRoute exact path="/mobile" component={MobilePay} />

                    <PrivateRoute exact path="/:serviceId?" component={Recharge} />

                    <PrivateRoute exact path="/landline" component={LandlinePay} />

                    <PrivateRoute exact path="/electricity" component={ElectricityPay} />

                    <PrivateRoute exact path="/gas" component={GasPay} />

                    <PrivateRoute exact path="/broadband" component={BroadbandPay} />

                    <PrivateRoute exact path="/dth" component={DthPay} />

                    <PrivateRoute exact path="/datacard" component={DatacardPay} />

                    <PrivateRoute exact path="/water" component={WaterPay} />

                </Switch>
            );
        }
    };
    render(){

        return(
            <React.Fragment>
            {
                this.renderRoutes()
            }
            </React.Fragment>
        );
    }
}