import React from 'react';
import { withRouter} from 'react-router-dom';
import {Button, Intent} from "@blueprintjs/core";

class SingleRow extends React.Component {

    getPlanAmount = (data) =>{
        window.scrollTo(0, 0);
        this.props.onSelectAmount(data);
    };

    render(){
        return(
            <div className="row no-gutters bill-row my-4">
                <div className="col-md-1 col-sm-2 col-2 text-center">
                    <div className="plan-price">{this.props.data.amount}</div>
                </div>
                <div className="col-md-5 col-sm-10 col-10 pl-3 pl-s-1">
                    <div className="plan-desc-head">{this.props.data.plan_name}</div>
                    <div className="plan-desc f-12" dangerouslySetInnerHTML={{ __html : this.props.data.plan_desc}} />
                </div>
                <div className="col-md-2 col-sm-4 col-4 text-center pl-3 pr-3 f-12 px-s-1 px-sm-1 flex-middle">{this.props.data.applicable}</div>
                <div className="col-md-2 col-sm-4 col-4 text-center f-12 flex-middle">{this.props.data.validity}</div>
                <div className="col-md-2 col-sm-4 col-4 text-right bill-btn">
                    <Button intent={Intent.PRIMARY} onClick={() => this.getPlanAmount(this.props.data)} >Get</Button>
                </div>
            </div>
        );
    }
}

export default withRouter(SingleRow);