import React from 'react';
import Loading from "./Plans/Loading";
import SingleRow from "./Plans/SingleRow";
import {ssoInstance} from "gg-react-utilities";
import config from "../../core/Config";
import Select from "react-select";
import * as _ from "lodash";

const Unstable = React.memo(function(props) {
    return (
        props.loadingData ?
        <Loading />
        :
            props.plans.length ?
            <React.Fragment>
                {
                    props.plans.map((plan, key) => {
                        return <SingleRow data={plan} key={key} onSelectAmount={(amount) => props.getPlanAmount(amount)}/>
                    })
                } 

                {
                     props.loadMoreUrl ?
                     <span onClick={props.loadMoreData} className="view-all bp3-button bp3-intent-primary" style={{"marginLeft":"40%", "marginTop" :"20px"}}>View More</span>
                 :
                     ''
                }                              
            </React.Fragment>
            :
            <div className="text-center mb-4">No Plans Found.</div>
    );
});

export default class Plans extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            serviceProvider: props.serviceProvider,
            serviceId: props.serviceId,
            circleId: props.circleId,
            loadingData: true,
            plans: [],
            circleOptions:[],
            iscircleOptionsLoading: true,
            loadMoreUrl:'',
            currentpage:1,
            circleSelection:''
        }
    }
    componentDidMount(){
        this.getPlanOptions(this.state.serviceId, this.state.serviceProvider, this.state.circleId,true)
        this.getCircles()
    }
    
    componentWillReceiveProps(nextProps){

        let oldProvider = this.state.serviceProvider;
        let oldService = this.state.serviceId;
        let oldCircleId = this.state.circleId;

        if( (oldProvider !== nextProps.serviceProvider) || (oldService !== nextProps.serviceId) || (oldCircleId !== nextProps.circleId)) {
            this.setState(
                {serviceProvider: nextProps.serviceProvider, serviceId: nextProps.serviceId, circleId: nextProps.circleId, circleSelection:''},
                this.getPlanOptions(nextProps.serviceId, nextProps.serviceProvider, nextProps.circleId,true)
            );
        }
    }

    getPlanOptions(serviceId, serviceProvider, circleId,clearPrevPlans){
        
        this.setState({loadingData: true});
        let options = {service_id: serviceId, service_provider_id: serviceProvider, circle_id: circleId};
        
        ssoInstance.post(config.GET_RECHARGE_PLAN+"?&page="+this.state.currentpage, options).then((res) => {

            let plans = this.state.plans;
            if(clearPrevPlans){
                plans=[];
            }

            res.data.data.planList.data.map((single) => {
                let applicable = "";
                single.circle.map((circleData) => {
                   if(!applicable) {applicable = circleData.circle_name}
                   else { applicable = applicable+", "+circleData.circle_name}
                   return true;
                });
                plans.push({
                    price: single.amount,
                    plan: single.plan_name,
                    desc: single.plan_desc,
                    applicable: applicable,
                    validity: single.plan_validity
                });
                return true;
            });
            this.setState({plans, loadingData: false, loadMoreUrl:res.data.data.planList.next_page_url});
        });
    }

    getCircles() {
        let options = {};
        let circleOptions = [];
        let circleSelection = {};

        ssoInstance.get(config.GET_CIRCLES, options).then((res) => {
            
            res.data.data.map((single) => {
                if(single.circle_id === parseInt(this.state.circleId, 10)){
                    circleSelection = {value: single.circle_id, label: single.circle_name};
                }
                circleOptions.push({value: single.circle_id, label: single.circle_name});
                return true;
            });
            this.setState({circleOptions, iscircleOptionsLoading: false});
            if(!_.isEmpty(circleSelection)) this.setState({circleSelection});
        });
    }
    
    onCircleChange = (circle) => { 
        this.setState({ circleSelection: circle ,currentpage:1},this.filterChange);
    }
    filterChange=()=>{
        this.getPlanOptions(this.state.serviceId, this.state.serviceProvider, this.state.circleSelection.value,true);
    }
    nextPage=()=>{
        this.getPlanOptions(this.state.serviceId, this.state.serviceProvider, this.state.circleSelection.value,false);
    }
    loadMoreData=()=> {
        this.setState({ currentpage: this.state.currentpage + 1 },this.nextPage );
    }
    
    render(){
        return(
            <div className="bills">
                <div className="col-sm-12  d-flex">
                    <div className="col-sm-8"><h1 className="bp3-text-xlarge heading py-4 pl-s-3">Recharge Plans</h1></div>
                    <div className="col-sm-4 py-4 pl-s-3">
                            <Select
                                className="react-select"
                                placeholder="Select Circle"
                                style={{marginTop: "20px"}}
                                value={this.state.circleSelection}
                                onChange={this.onCircleChange}
                                isLoading={this.state.iscircleOptionsLoading}
                                options={this.state.circleOptions}
                            />
                    </div>
                </div>
                        

                <Unstable loadingData={this.state.loadingData}
                        loadMoreUrl={this.state.loadMoreUrl}
                        loadMoreData={this.loadMoreData}
                          plans={this.state.plans}
                          getPlanAmount={(amount) => this.props.setPlanAmount(amount)}
                />
            </div>
        );
    }
}
