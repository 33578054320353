import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Auth} from 'gg-react-utilities';

export default class NotLoggedIn extends Component{
    constructor(props){
        super(props);
        this.state = {
            headerText: props.text ? props.text : "Please login to view this details",
            showHomeLink: props.showHomeLink ? props.showHomeLink : false
        };
    }
    render(){
        return(

            <div className="row">
                <div className="col-md-12 text-center text-muted">
                    <div className="">
                        <center>
                            <h3 className="">{this.state.headerText}<br/>
                                <a href="/#" onClick={()=>{
                                    Auth.login(true)
                                }}>Login Now</a>
                                {
                                    this.state.showHomeLink ? " or " : ""
                                }
                                {
                                    this.state.showHomeLink ?
                                        <Link to='/'>Go to Home Page</Link>
                                    : ""
                                }
                            </h3>
                        </center>
                    </div>
                </div>
            </div>

        );
    }
}
