import React from 'react';
import {Checkbox, Classes, Dialog, Button, Intent} from "@blueprintjs/core";
import {ssoInstance} from "gg-react-utilities";
import {generateSessionId} from "../../core/utils/Helper";

export default class RedeemVoucher extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
            voucherIds: [],
            btnLoading:false,
            clearBtnLoading:false,
        }
    }

    handleClose = () => {
        this.setState({isOpen: false})
    }

    handleOpen = () => {
        this.setState({isOpen: true})
    }

    onCheckBoxChange(e) {
        const options = this.state.voucherIds;
        let index

        if (e.target.checked) {
            options.push(+e.target.value)
        } else {
            index = options.indexOf(+e.target.value)
            options.splice(index, 1)
        }
        this.setState({ voucherIds: options })
    }

    redeemVoucher = () => {

        let validateData = this.props.orderData;

        validateData.platform = 'web';
        validateData.session_id = generateSessionId();
        validateData.is_upi = this.props.paymentMethod;

        this.setState({btnLoading: true});

        let validateURL = '';
        if(this.state.voucherIds.length > 0) {
            validateData.voucher_ids = this.state.voucherIds;
            validateURL = this.props.validateVoucherUrl;
        } else {

            validateURL = this.props.validateUrl;
        }

        ssoInstance.post(validateURL, validateData).then((res) => {
            this.setState({orderTotal: res.data.data.payment_data, paymentLoading: false, allowedPayment: true, btnLoading:false});
            this.props.priceData(res.data.data, this.state.voucherIds);
            this.handleClose();
        }).catch((error) => {
            let message = error.response.data.message ? error.response.data.message : 'Something went wrong, try again.';
            this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message, btnLoading:false});
        });
    }

    clearVoucher = () => {

        let validateData = this.props.orderData;

        validateData.platform = 'web';
        validateData.session_id = generateSessionId();
        validateData.is_upi = this.props.paymentMethod;

        this.setState({clearBtnLoading: true});

        let validateURL = '';

        validateData.session_id = generateSessionId();
        validateURL = this.props.validateUrl;


        ssoInstance.post(validateURL, validateData).then((res) => {
            this.setState({orderTotal: res.data.data.payment_data, paymentLoading: false, allowedPayment: true, clearBtnLoading:false,voucherIds: []},()=>{
                this.props.priceData(res.data.data, []);
            });
            this.handleClose();
        }).catch((error) => {
            let message = error.response.data.message ? error.response.data.message : 'Something went wrong, try again.';
            this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message, clearBtnLoading:false});
        });
    }

    render() {
        return (
            <React.Fragment>
                <div
                    className="pay-btn row"
                    style={{
                        borderBottom: '2px solid #CBCBCB'
                    }}>
                    <div
                        className="col-md-6 col-sm-6 col-6 text-left bp3-text-xlarge heading mb-3 mt-2">
                        Apply GG Voucher
                    </div>
                    <div className="col-md-6 col-sm-6 col-6 text-right">
                        <button
                            type="button"
                            className="bp3-button bp3-button-sm bp3-intent-primary"
                            style={{
                                minWidth: '70px',
                                minHeight: '27px',
                                padding: '2px 8px',
                                fontSize: '13px'
                            }}
                            onClick={() => this.handleOpen()}>Apply</button>
                    </div>
                </div>

                <Dialog
                    isOpen={this.state.isOpen}
                    title={this.props.title}
                    className={this.props.className ? this.props.className : ''}
                    icon={this.props.icon ? this.props.icon : "info-sign"}
                    onClose={this.handleClose}
                    {...this.props}>

                    <h3 className="bp3-text-xlarge heading ml-5 mt-4">Apply GG Voucher</h3>

                    <div className={Classes.DIALOG_BODY + ' voucher-list-model'}>
                        {
                            this.props.userGGVouchers && this.props.userGGVouchers.length > 0 ?
                                <React.Fragment>
                                    {
                                        this.props.userGGVouchers.map((voucher, key) => {
                                            return (
                                                <div className="pay-btn row" key={key}>
                                                    <div className="col-md-8 col-sm-8 col-8 text-left bp3-text-xlarge heading mb-3 mt-2">
                                                        <p>₹ {voucher.current_balance}</p>
                                                        <p>#{voucher.id} - Valid Till {voucher.valid_till}</p>
                                                    </div>
                                                    <div className="col-md-4 col-sm-8 col-8 text-right">
                                                        <Checkbox checked={this.state.voucherIds.includes(voucher.id)} value={voucher.id} className="sb-chk-text" onChange={this.onCheckBoxChange.bind(this)}></Checkbox>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </React.Fragment>
                                :
                                'You have not any voucher'
                        }
                    </div>
                    {
                        this.props.userGGVouchers && this.props.userGGVouchers.length > 0 ?
                            <React.Fragment>
                                <div className="pay-btn row mt-2 mr-4">
                                    <div className="col-sm-12 col-12 text-right">
                                        <Button intent={Intent.WARNING} disabled={this.state.btnLoading || this.state.clearBtnLoading} onClick={() => this.handleClose()}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button loading={this.state.clearBtnLoading} disabled={this.state.btnLoading} intent={Intent.DANGER} onClick={() => this.clearVoucher()}>Clear</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button loading={this.state.btnLoading} intent={Intent.PRIMARY} disabled={this.state.clearBtnLoading} onClick={() => this.redeemVoucher()}>Apply</Button>
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            'You have not any voucher'
                    }

                    {this.props.footer
                        ? <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                {this.props.footer}
                            </div>
                        </div>
                        : ''
                    }
                </Dialog>
            </React.Fragment>
        )
    }
}