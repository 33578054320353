import React from 'react';
import {Dialog,Button, Intent, RadioGroup, Radio, Toaster, Position } from "@blueprintjs/core";
import {Spinner} from "@blueprintjs/core";
import HighlightOffRounded from "@material-ui/icons/HighlightOffRounded"
import {ssoInstance} from "gg-react-utilities";
import RedeemVoucher from "../RedeemVoucher";
import config from "../../../core/Config";
import QRCode from "react-qr-code";
import moment from "moment";

export default class Step2 extends React.Component {
    constructor(props){
        super(props);
        this.payuform = React.createRef();

        this.state = {
            loading: props.loading,
            allowed: props.allowed,
            data: props.data ? props.data : [],
            paymentError: props.paymentError ? props.paymentError : "",
            allowEdit: props.allowEdit,
            orderData: props.orderData,
            userGGVouchers: props.userGGVouchers,
            pgData: {},
            createPayuForm: false,
            selectedVouchers:[],
            payBtnLoading: false,
            validateVoucherUrl:props.validateVoucherUrl,
            validateUrl:props.validateUrl,
            paymentMethod:"1",
            upiLoading:false,
            upiOpen: false,
            upiURL: '',
            time:"05",
            pgDetails:{}
        };
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            loading: nextProps.loading,
            allowed: nextProps.allowed,
            data: nextProps.data,
            paymentError: nextProps.paymentError,
            allowEdit: nextProps.allowEdit,
            orderData: nextProps.orderData,
            userGGVouchers: nextProps.userGGVouchers
        });
    }
    renderComponents = (loading, allowEdit, data, allowed, paymentError, orderData, payBtnLoading,upiLoading) =>{
        if(loading){
            return (<div style={{marginTop: "150px"}}>
                <Spinner size={50}/>
            </div>);
        }else{
            if(allowEdit){
                return <div style={{ margin: "35px auto",textAlign: "center", width: "100%"}}>
                    Please Provide All the required details.
                </div>
            }
            else{
                if(allowed){
                    return (
                        <React.Fragment>
                            <RedeemVoucher paymentMethod={this.state.paymentMethod} priceData={(payment_data, voucherIds) => this.priceData(payment_data, voucherIds)} orderData={orderData} userGGVouchers={this.state.userGGVouchers} validateVoucherUrl={this.state.validateVoucherUrl} validateUrl={this.state.validateUrl} />
                            <h3 className="bp3-text-xlarge heading mb-3 mt-4">Payment Summary</h3>
                            <div className="payment-summary">
                                {
                                    data.map((single, key) => {

                                        return <div key={key} className={ ((data.length - 1) === key) ? "pay-sum-row total" : "pay-sum-row"}>
                                            <div className="item">{single.title}</div>
                                            <div className="price">
                                                {key ? "₹ " : ""}
                                                {single.value}</div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <RadioGroup
                                        className='d-flex mt_10 mt-3'
                                        disabled={upiLoading}
                                        selectedValue={this.state.paymentMethod}
                                        onChange={(e) => {
                                            this.setState({
                                                paymentMethod: e.target.value
                                            }, () => {

                                                this.setState({
                                                    upiLoading: true
                                                })

                                                this.validateAPI(this.state.paymentMethod);
                                            })
                                        }}>
                                        <Radio className="mr-4" label={'UPI (PG charges not applicable)'}
                                               value={"1"}/>
                                        <Radio label={'Debit / Credit / Net Banking / Wallet'} value={"0"}/>
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="pay-btn row">
                                <div className="col-md-6 col-sm-6 col-6 text-left">
                                    <Button disabled={upiLoading || payBtnLoading} intent={Intent.WARNING} onClick={() => this.retry()}>Edit</Button>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6 text-right">
                                    <Button disabled={upiLoading} loading={payBtnLoading} intent={Intent.PRIMARY} onClick={() => this.payNowAction()}>Pay Now</Button>
                                </div>
                            </div>
                            <div className="bp3-card bp3-elevation-0 col-12" style={{padding:'15px',marginTop:'20px'}}>
                                <p>*Attention!</p><span>Do you want to make transaction with 0% payment gateway charge? contact our UPOS agent.</span>
                            </div>
                            <Dialog
                                isOpen={this.state.upiOpen}
                                title={"UPI Payment"}
                                icon={this.props.icon ? this.props.icon : "info-sign"}
                                onClose={this.onUpiCloseModal}
                                {...this.props}
                            >
                                <div className='ggvoucher'>
                                    <h2></h2>

                                    <div bp="grid">
                                        <div bp="12">
                                            <center>
                                                <QRCode
                                                    value={this.state.upiURL}
                                                    title={this.state.upiURL}
                                                    level="Q" />
                                            </center>
                                        </div>

                                        <div bp="12 ">
                                            <center>
                                                <h4 style={{color:"red"}}>Make Payment: {this.state.time}</h4>
                                            </center>
                                        </div>

                                    </div>

                                </div>
                            </Dialog>
                        </React.Fragment>
                    )
                }
                else{
                    return <div className="align-items-center d-flex flex-column h-100 justify-content-center red-text text-center">
                        <HighlightOffRounded style={{fontSize: "35", margin: "0px auto 30px auto", textAlign: "center", width: "100%"}} />
                        {paymentError}
                        <div style={{textAlign: "center", width: "100%", margin: "15px auto"}}>
                            <Button icon="refresh" className="bp3-secondary" intent={Intent.NONE} onClick={() => this.retry()}>Change Details or Retry</Button>
                        </div>
                    </div>;
                }
            }
        }
    };

    validateAPI(upi){
        let validateData = this.props.orderData;

        validateData.is_upi = upi;

        let url = this.state.validateUrl;

        if(this.state.selectedVouchers.length > 0){
            url = this.state.validateVoucherUrl;
            validateData.voucher_ids = this.state.selectedVouchers;
        }

        ssoInstance.post(url, validateData).then((res) => {
            this.setState({
                data:res.data.data,
                upiLoading:false
            })
        }).catch((error) => {
            let message = error.response.data.message ? error.response.data.message : 'Something went wrong, try again.';
            this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message, btnLoading:false});
        });
    }

    retry(){
        this.props.allowEditing();
    }

    priceData = (data, voucherIds) => {
        this.setState({
            data:data,
            selectedVouchers:voucherIds
        });
    }

    paymentTime = () => {
        let secTimer = setInterval(() => {

            let time = moment(this.state.time, 'mmss').subtract(1, 'seconds').format('mm:ss');
            this.setState({
                time
            })

            if(this.state.upiOpen == false){
                clearInterval(secTimer);
            }

            if(moment(time, 'mmss').format('ss') % 15 == 0){
                this.PgCheck();
            }

            if(time == '00:00'){
                clearInterval(secTimer);
                window.location.href = "/?callback=1&status=0&message=We are processing your payment request please check back in sometimes!&title=Payment Awaiting!";
            }

        }, 1000)

    }

    onUpiCloseModal = () => {
        this.setState({ upiOpen: false,payBtnLoading:false });
    };

    PgCheck = () => {

        ssoInstance.get(config.API_URL + "upi-order-status-check/" + this.state.pgDetails.pg.txnid)
            .then((res)=>{
                let source = res.data;
                if(source.status){
                    if(source.data.payment_status) {
                        window.location.href = "/?callback=1&status=1&message="+source.message;
                        //window.location.href = Config.APP_URL + "/payment/success?msg=" + source.message + "&title=Payment Success!";
                    }
                }
            })
            .catch((error)=>{
                this.setState({
                    payBtnLoading:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })

    }

    payNowAction = () => {

        let requestData = {};
        requestData = this.state.orderData;
        requestData.voucher_ids = this.state.selectedVouchers;
        this.setState({payBtnLoading: true});

        ssoInstance.post(config.API_URL +"mobile-prepaid/order", requestData).then((res) => {
            let pgData = res.data.data.pg;

            if(res.data.status == true) {
                if (res.data.data.is_payment_required === true && res.data.data.is_upi == true) {
                    this.setState({upiOpen: true, upiURL: res.data.data.upi_url, pgDetails: res.data.data});
                    this.paymentTime();
                } else if (res.data.data.is_payment_required === true) {
                    this.setState({pgData, createPayuForm: true, payBtnLoading: false});
                    window.document.payuform.submit()
                } else if (res.data.data.is_payment_required === false) {
                    let afterRequestData = res.data.data.pg;

                    this.afterPaymentRequest(afterRequestData);

                } else {
                    window.location.href = "/?callback=1&status=0&message=" + res.data.message;
                }
            }else{

                Toaster.create({position: Position.TOP}).show({
                    message: "Something went wrong.",
                    intent: Intent.DANGER
                });
            }

        }).catch((error) => {
            this.setState({payBtnLoading: false});
        });

    };

    afterPaymentRequest = (requestData) =>{

        ssoInstance.post(config.API_URL + "order/callback", requestData).then((res) => {
            if(res.data.status == true) {
                window.location.href = "/?callback=1&status=1&message="+res.data.message;
            }else{
                window.location.href = "/?callback=1&status=0&message=" + res.data.message;
            }
        }).catch((error) => {
            window.location.href = "/?callback=1&status=0&message=Something went wrong.";
        });
    }

    renderPayUForm(){
        let pgData = this.state.pgData;
        return React.createElement('div', {className: "hide"},
            React.createElement('form', {method: 'post', action: pgData.url, ref: this.payuform, id: "payuform", name: "payuform"},

                React.createElement('input', {type: 'hidden', className: 'hide', name: 'key', defaultValue: pgData.key}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'txnid', defaultValue: pgData.txnid}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'amount', defaultValue: pgData.amount}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'productinfo', defaultValue: pgData.productinfo}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'firstname', defaultValue: pgData.firstname}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'email', defaultValue: pgData.email}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'phone', defaultValue: pgData.phone}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'service_provider', defaultValue: pgData.service_provider}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf1', defaultValue: pgData.udf1}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf2', defaultValue: pgData.udf2}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf3', defaultValue: pgData.udf3}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf4', defaultValue: pgData.udf4}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf5', defaultValue: pgData.udf5}),

                React.createElement('input', {type: 'hidden', className: 'hide', name: 'hash', defaultValue: pgData.hash}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'surl', defaultValue: pgData.surl}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'furl', defaultValue: pgData.furl}),
            )
        );
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.renderComponents(this.state.loading, this.state.allowEdit,
                        this.state.data.payment_data, this.state.allowed, this.state.paymentError, this.state.orderData, this.state.payBtnLoading, this.state.upiLoading)
                }
                {
                    this.state.createPayuForm ?
                        this.renderPayUForm()
                        : ""
                }
            </React.Fragment>
        );
    }
}

const TotalData = React.memo(function(props) {
    let totalRows = props.data.length;
    return (
        <React.Fragment>
            <h3 className="bp3-text-xlarge heading mb-3 mt-4">Payment Summary</h3>
            <div className="payment-summary">
                {
                    props.data.map((single, key) => {

                        return <div key={key} className={ ((totalRows - 1) === key) ? "pay-sum-row total" : "pay-sum-row"}>
                            <div className="item">{single.title}</div>
                            <div className="price">
                                {key ? "₹ " : ""}
                                {single.value}</div>
                        </div>
                    })
                }
            </div>
            <div className="pay-btn row">
                <div className="col-md-6 col-sm-6 col-6 text-left">
                    <Button intent={Intent.WARNING} onClick={() => props.retry()}>Edit</Button>
                </div>
                <div className="col-md-6 col-sm-6 col-6 text-right">
                    <Button loading={props.payBtnLoading} intent={Intent.PRIMARY} onClick={() => props.payNow()}>Pay Now</Button>
                </div>
            </div>

            <div className="bp3-card bp3-elevation-0 col-12" style={{padding:'15px',marginTop:'20px'}}>
                <p>*Attention!</p><span>Do you want to make transaction with 0% payment gateway charge? contact our UPOS agent.</span>
            </div>
        </React.Fragment>
    );
});