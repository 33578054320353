import React from 'react';
import TopBar from "../core/components/TopBar";
import NavBar from "../core/components/NavBar";
import ElectricityPaySteps from "./components/ElectricityPaySteps";

export default class ElectricityPay extends React.Component {
    render(){
        return(
            <div className="container">
                <TopBar title="Bill Pay and Recharge" />
                <NavBar allprops={this.props} />
                <div className="page-content-right">
                    <ElectricityPaySteps />
                </div>
            </div>
        );
    }
}