import React from 'react';
import Step1 from "./MobilePaySteps/Step1";
import Step2 from "./MobilePaySteps/Step2";
import RecentBills from "./RecentBills";
import config from "../../core/Config";
import Plans from "./Plans";
import OldRecharges from "./OldRecharges";
import {ssoInstance} from "gg-react-utilities";
import {getAllUrlParams} from "../../core/utils/Helper";
import OrderCallback from "../../core/components/Order/Callback";

export default class MobilePaySteps extends React.Component{
    constructor(props){
        super(props);

        let urlData = getAllUrlParams();
        this.state = {
            step: 1,
            allowEdit: true,
            rechargeType: urlData.type ? parseInt(urlData.type, 10) : 2,
            paymentLoading: false,
            allowedPayment: false,
            orderData: {},
            userGGVouchers: "",
            amount: "",
            callback: urlData.callback ? urlData.callback : false,
            callbackType: urlData.status ? parseInt(urlData.status, 10) : false,
            operator:'',
            circle:'',
            planDetails:{}
        };
    }
    validateRequest = (validateData,sendPlanId) => {
        this.setState({paymentLoading: true, allowEdit: false, step: 2});
        validateData.platform = "web";
        validateData.plan_offer_id = this.state.planDetails.id;
        validateData.recharge_type = this.state.planDetails.recharge_type;

        ssoInstance.post(config.API_URL+"mobile-prepaid/validate", validateData)
            .then((res) => {
                this.setState({orderTotal: res.data.data, userGGVouchers: res.data.data.gg_vouchers_list, orderData: validateData, paymentLoading: false, allowedPayment: true});
            })
            .catch((error) => {
                let message = error.response.data.message ? error.response.data.message : 'Something went wrong, try again.';
                this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message});
            });
    };
    setOrderPaymentTotals(data, validateData){
        this.setState({orderTotal: data, orderData: validateData, paymentLoading: false, allowedPayment: true});
    }
    setValidateError(message){
        this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message});
    }
    setDetailLoading(value){
        this.setState({paymentLoading: value});
    }
    render(){
        return(
            <React.Fragment>
                {
                    this.state.callback ?
                        <OrderCallback status={this.state.callbackType}
                                       hideCallback={() => this.setState({callback: false})}
                        />
                        :
                        <React.Fragment>
                            <div className="border bill-pay row no-gutters">
                                <div className={this.state.step === 1 ? "step-1-full step-1" : "col-md-6 col-sm-12 col-12 step-1"}>
                                    <Step1 step={this.state.step}
                                           mobile={this.state.mobile}
                                           validate={this.state.validate}
                                           amount={this.state.amount}
                                           operator={this.state.operator}
                                           rechargeType={this.state.rechargeType}
                                           onOperatorChange={(operator,circle) =>{ this.setState({operator,circle})}}
                                           amountSet={(amount) =>{ this.setState({amount})}}
                                           changeRechargeType={(type) => this.setState({rechargeType: type})}
                                           setDetailLoading={(value) => this.setDetailLoading(value)}
                                           allowEdit={this.state.allowEdit}
                                           setValidateError={(data) => this.setValidateError(data)}
                                           validateData={(validateData,sendPlanId) => this.validateRequest(validateData,sendPlanId)}
                                    />
                                </div>
                                {
                                    this.state.step === 2 ?
                                        <div className="col-md-6 col-sm-12 col-12 step-2">
                                            <Step2
                                                orderUrl={config.ORDER_RECHARGE}
                                                loading={this.state.paymentLoading}
                                                allowed={this.state.allowedPayment}
                                                paymentError={this.state.paymentError}
                                                data={this.state.orderTotal}
                                                allowEdit={this.state.allowEdit}
                                                allowEditing={() => this.setState({allowEdit: true, step: 1})}
                                                orderData={this.state.orderData}
                                                userGGVouchers={this.state.userGGVouchers}
                                                validateVoucherUrl={config.VALIDATE_PREPAID_VOUCHER_RECHARGE}
                                                validateUrl={config.REMOVE_PREPAID_VOUCHER_RECHARGE}
                                            />
                                        </div>
                                        : ""
                                }
                            </div>
                            {
                                this.state.step === 1 ?

                                    this.state.rechargeType === 2 ?
                                        <div className="border-bottom">
                                            {
                                                this.state.operator != '' && this.state.circle != '' ?
                                                    <Plans serviceProvider={this.state.operator}
                                                           serviceId={this.state.rechargeType}
                                                           circleId={this.state.circle}
                                                           setPlanAmount={(data) =>{
                                                               this.setState({
                                                                   amount:data.amount,
                                                                   planDetails:data
                                                               })
                                                           }}/>
                                                           : ""
                                            }

                                            <OldRecharges serviceId={this.state.rechargeType}
                                                          setRepeatData={(operator, amount) => this.setState({operator, amount})}
                                            />
                                        </div>
                                        :
                                        <div className="border-bottom">
                                            <RecentBills serviceId={this.state.rechargeType}
                                                         title="Recently Paid Mobile Postpaid Bills" />
                                        </div>
                                    : ""

                            }
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}