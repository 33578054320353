import React from 'react';

export default class LoadingRecentBillsBills extends React.Component {

    render(){
        return(
            <p className="text-center p-3">
                Loading Recent Bills...
            </p>
        );
    }
}