import React from 'react';
import TopBar from "../core/components/TopBar";
import NavBar from "../core/components/NavBar";
import WaterPaySteps from "./components/WaterPaySteps";

export default class WaterPay extends React.Component {
    render(){
        return(
            <div className="container">
                <TopBar title="Bill Pay and Recharge" />
                <NavBar allprops={this.props} />
                <div className="page-content-right">
                    <WaterPaySteps />
                </div>
            </div>
        );
    }
}