import React from 'react';
import Step1 from "./MobilePaySteps/Step1";
import Step2 from "./MobilePaySteps/Step2";
import RecentBills from "./RecentBills";
import config from "../../core/Config";
import Plans from "./Plans";
import OldRecharges from "./OldRecharges";
import {ssoInstance} from "gg-react-utilities";
import {getAllUrlParams} from "../../core/utils/Helper";
import OrderCallback from "../../core/components/Order/Callback";
import TopBar from "../../core/components/TopBar";
import NavBar from "../../core/components/NavBar";

export default class MobilePaySteps extends React.Component{
    constructor(props){
        super(props);

        let urlData = getAllUrlParams();
        this.state = {
            step: 1,
            allowEdit: true,
            rechargeType: urlData.type ? parseInt(urlData.type, 10) : 2,
            paymentLoading: false,
            allowedPayment: false,
            orderData: {},
            userGGVouchers: "",
            amount: "",
            callback: urlData.callback ? urlData.callback : false,
            callbackType: urlData.status ? parseInt(urlData.status, 10) : false,
            services:{}
        };
    }
    validateRequest = (validateData) => {
        this.setState({paymentLoading: true, allowEdit: false, step: 2});
        validateData.platform = "web";
        ssoInstance.post(config.API_URL+"validate", validateData)
            .then((res) => {
                this.setState({orderTotal: res.data.data, userGGVouchers: res.data.data.gg_vouchers_list, orderData: validateData, paymentLoading: false, allowedPayment: true});
            })
            .catch((error) => {
                let message = error.response.data.message ? error.response.data.message : 'Something went wrong, try again.';
                this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message});
            });

    };
    setOrderPaymentTotals(data, validateData){
        this.setState({orderTotal: data, orderData: validateData, paymentLoading: false, allowedPayment: true});
    }
    setValidateError(message){
        this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message});
    }
    setDetailLoading(value){
        this.setState({paymentLoading: value});
    }

    selectedServices(data){
        let urlData = getAllUrlParams();
        this.setState({
            services:data,
            step: 1,
            allowEdit: true,
            rechargeType: urlData.type ? parseInt(urlData.type, 10) : 2,
            paymentLoading: false,
            allowedPayment: false,
            orderData: {},
            userGGVouchers: "",
            amount: "",
            callback: urlData.callback ? urlData.callback : false,
            callbackType: urlData.status ? parseInt(urlData.status, 10) : false,
        })
    }
    render(){
        return(
            <React.Fragment>
                <TopBar title="Bill Pay and Recharge" />
                <NavBar {...this.props}  isStatic={false} selectedServices={(data) => { this.selectedServices(data) }} />
                <div className="page-content-right pb-5">
                {
                    this.state.callback ?
                        <OrderCallback status={this.state.callbackType}
                                       hideCallback={() => this.setState({callback: false})}
                        />
                        :
                        <React.Fragment>
                            <div className="border bill-pay row no-gutters">
                                <div className={this.state.step === 1 ? "step-1-full step-1" : "col-md-6 col-sm-12 col-12 step-1"}>
                                    <Step1 step={this.state.step}
                                           services={this.state.services}
                                           validate={this.state.validate}
                                           operator={this.state.operator}
                                           rechargeType={this.state.rechargeType}
                                           onOperatorChange={(operator) => this.setState({operator})}
                                           changeRechargeType={(type) => this.setState({rechargeType: type})}
                                           setDetailLoading={(value) => this.setDetailLoading(value)}
                                           allowEdit={this.state.allowEdit}
                                           setValidateError={(data) => this.setValidateError(data)}
                                           validateData={(validateData) => this.validateRequest(validateData)}
                                    />
                                </div>
                                {
                                    this.state.step === 2 ?
                                        <div className="col-md-6 col-sm-12 col-12 step-2">
                                            <Step2
                                                services={this.state.services}
                                                orderUrl={config.ORDER_RECHARGE}
                                                loading={this.state.paymentLoading}
                                                allowed={this.state.allowedPayment}
                                                paymentError={this.state.paymentError}
                                                data={this.state.orderTotal}
                                                allowEdit={this.state.allowEdit}
                                                allowEditing={() => this.setState({allowEdit: true, step: 1})}
                                                orderData={this.state.orderData}
                                                userGGVouchers={this.state.userGGVouchers}
                                                validateVoucherUrl={config.VALIDATE_VOUCHER}
                                                validateUrl={config.REMOVE_VOUCHER}
                                            />
                                        </div>
                                        : ""
                                }
                            </div>
                            {
                                this.state.step === 1 ?

                                    this.state.rechargeType === 2 ?
                                        <div className="border-bottom">
                                            {
                                                this.state.operator === 77 || this.state.operator === 18 ?
                                                    <Plans serviceProvider={this.state.operator}
                                                           serviceId={this.state.rechargeType}
                                                           circleId=''
                                                           setPlanAmount={(amount) => this.setState({amount})}/>
                                                           : ""
                                            }

                                            <OldRecharges services={this.state.services}
                                                          serviceId={this.state.rechargeType}
                                                          setRepeatData={(operator, amount) => this.setState({operator, amount})}
                                            />
                                        </div>
                                        :
                                        <div className="border-bottom">
                                            <RecentBills serviceId={this.state.rechargeType}
                                                         title={"Recently Paid " + this.state.services.service_type + " Bills"} />
                                        </div>
                                    : ""

                            }
                        </React.Fragment>
                }
                </div>
            </React.Fragment>
        );
    }
}