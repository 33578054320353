import React from 'react';
import TopBar from "../core/components/TopBar";
import NavBar from "../core/components/NavBar";
import MobilePaySteps from "./components/MobilePaySteps";

export default class MobilePay extends React.Component {
    render(){
        return(
            <div className="container pb-5">
                <TopBar title="Bill Pay and Recharge" />
                <NavBar {...this.props}  isStatic={true} />
                <div className="page-content-right pb-5">
                    <MobilePaySteps />
                </div>
            </div>
        );
    }
}