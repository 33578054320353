import React from 'react'
import { Route } from 'react-router-dom'
import {Header, Footer} from 'gg-react-utilities';

const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <div className="recharge">
            <Header Extension="local"/>
            <Component {...props} />
            <Footer />
        </div>
    )}/>
);

export default PrivateRoute;
