import React from 'react';
import Step1 from "./ElectricityPaySteps/Step1";
import Step2 from "../../core/components/Step2";
import RecentBills from "../../mobile/components/RecentBills";
import config from "../../core/Config";
import {ssoInstance} from 'gg-react-utilities';
import OrderCallback from "../../core/components/Order/Callback";
import {getAllUrlParams} from "../../core/utils/Helper";

export default class ElectricityPaySteps extends React.Component{
    constructor(props){
        super(props);

        let urlData = getAllUrlParams();
        this.state = {
            step: 1,
            allowEdit: true,
            paymentLoading: false,
            allowedPayment: false,
            orderData: {},

            callback: urlData.callback ? urlData.callback : false,
            callbackType: urlData.status ? parseInt(urlData.status, 10) : false,
        };
    }
    validateRequest = (validateData) => {
        this.setState({paymentLoading: true, allowEdit: false, step: 2});
        validateData.platform = "web";
        ssoInstance.post(config.API_URL+"electricity/validate", validateData)
            .then((res) => {
                this.setState({orderTotal: res.data.data.payment_data, userGGVouchers: res.data.data.gg_vouchers_list, orderData: validateData, paymentLoading: false, allowedPayment: true});
            })
            .catch((error) => {
                let message = error.response.data.message ? error.response.data.message : 'Something went wrong, try again.';
                this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message});
            });
    };
    setOrderPaymentTotals(data, validateData){
        this.setState({orderTotal: data, orderData: validateData, paymentLoading: false, allowedPayment: true});
    }
    setValidateError(message){
        this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message});
    }
    setDetailLoading(value){
        this.setState({paymentLoading: value});
    }
    render(){
        return(
            <React.Fragment>
                {
                    this.state.callback ?
                        <OrderCallback status={this.state.callbackType}
                                       hideCallback={() => this.setState({callback: false})}
                        />
                        :
                        <React.Fragment>
                            <div className="border bill-pay row no-gutters">
                                <div className={this.state.step === 1 ? "step-1-full step-1" : "col-md-6 col-sm-12 col-12 step-1"}>
                                    <Step1 step={this.state.step}
                                           onOperatorChange={(operator) => this.setState({operator})}
                                           setDetailLoading={(value) => this.setDetailLoading(value)}
                                           allowEdit={this.state.allowEdit}
                                           setValidateError={(data) => this.setValidateError(data)}
                                           validateData={(validateData) => this.validateRequest(validateData)}
                                    />
                                </div>
                                {
                                    this.state.step === 2 ?
                                        <div className="col-md-6 col-sm-12 col-12 step-2">
                                            <Step2
                                                orderUrl={config.ORDER_ELECTRICITY}
                                                loading={this.state.paymentLoading}
                                                allowed={this.state.allowedPayment}
                                                paymentError={this.state.paymentError}
                                                data={this.state.orderTotal}
                                                allowEdit={this.state.allowEdit}
                                                allowEditing={() => this.setState({allowEdit: true, step: 1})}
                                                orderData={this.state.orderData}
                                                userGGVouchers={this.state.userGGVouchers}
                                                validateVoucherUrl={config.VALIDATE_VOUCHER_ELECTRICITY}
                                                validateUrl={config.API_URL+"electricity/validate"}
                                            />
                                        </div>
                                        : ""
                                }
                            </div>
                            {
                                this.state.step === 1 ?

                                    <div className="border-bottom">
                                        <RecentBills serviceId={config.TYPE_ELECTRICITY} title="Recent Paid Electricity Bills" />
                                    </div>
                                    : ""

                            }
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}