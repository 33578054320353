import React from 'react';
import {Button, Intent} from "@blueprintjs/core";

export default class Loading extends React.Component{

    render(){
        return(
            <div className="row no-gutters bill-row mt-3 mb-3">
                <div className="col-1 text-center">
                    <div className="plan-price bp3-skeleton">50</div>
                </div>
                <div className="col-5 pl-3">
                    <div className="plan-desc-head  bp3-skeleton">ISD Pack - All Customers</div>
                    <div className="plan-desc f-12  bp3-skeleton">ISD Talktime: Rs.435.65, which can be used only for making ISD calls while in India. For ISD Call rates, kindly visit www.jio.com</div>
                </div>
                <div className="col-3 text-center pl-3 pr-3 f-12 bp3-skeleton">All</div>
                <div className="col-1 text-center f-12 bp3-skeleton">28 Days</div>
                <div className="col-2 text-right plan-get">
                    <Button intent={Intent.PRIMARY} className="bp3-skeleton" >Get</Button>
                </div>
            </div>
        );
    }
}