import React from 'react';
import {Button, Intent} from "@blueprintjs/core";
import {Spinner} from "@blueprintjs/core";
import HighlightOffRounded from "@material-ui/icons/HighlightOffRounded"
import {ssoInstance} from "gg-react-utilities";
import RedeemVoucher from "./RedeemVoucher";

export default class Step2 extends React.Component {
    constructor(props){
        super(props);
        this.payuform = React.createRef();

        this.state = {
            loading: props.loading,
            allowed: props.allowed,
            data: props.data ? props.data : [],
            paymentError: props.paymentError ? props.paymentError : "",
            allowEdit: props.allowEdit,
            orderData: props.orderData,
            userGGVouchers: props.userGGVouchers,
            pgData: {},
            createPayuForm: false,
            selectedVouchers:[],
            payBtnLoading: false,
            validateVoucherUrl:props.validateVoucherUrl,
            validateUrl:props.validateUrl,
        };
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            loading: nextProps.loading,
            allowed: nextProps.allowed,
            data: nextProps.data,
            paymentError: nextProps.paymentError,
            allowEdit: nextProps.allowEdit,
            orderData: nextProps.orderData,
            userGGVouchers: nextProps.userGGVouchers
        });
    }
    renderComponents = (loading, allowEdit, data, allowed, paymentError, orderData, payBtnLoading) =>{
        if(loading){
            return (<div style={{marginTop: "150px"}}>
                <Spinner size={50}/>
            </div>);
        }else{
            if(allowEdit){
                return <div style={{ margin: "35px auto",textAlign: "center", width: "100%"}}>
                    Please Provide All the required details.
                </div>
            }
            else{
                if(allowed){
                    return (
                        <React.Fragment>
                            <RedeemVoucher priceData={(payment_data, voucherIds) => this.priceData(payment_data, voucherIds)} orderData={orderData} userGGVouchers={this.state.userGGVouchers} validateVoucherUrl={this.state.validateVoucherUrl} validateUrl={this.state.validateUrl} />
                            <TotalData payBtnLoading={payBtnLoading} data={data} payNow={() => this.payNowAction()} retry={() => this.retry()}/>
                        </React.Fragment>
                    )
                }
                else{
                    return <div className="align-items-center d-flex flex-column h-100 justify-content-center red-text text-center">
                        <HighlightOffRounded style={{fontSize: "35", margin: "0px auto 30px auto", textAlign: "center", width: "100%"}} />
                        {paymentError}
                        <div style={{textAlign: "center", width: "100%", margin: "15px auto"}}>
                            <Button icon="refresh" className="bp3-secondary" intent={Intent.NONE} onClick={() => this.retry()}>Change Details or Retry</Button>
                        </div>
                    </div>;
                }
            }
        }
    };

    retry(){
        this.props.allowEditing();
    }

    priceData = (data, voucherIds) => {
        this.setState({
            data:data,
            selectedVouchers:voucherIds
        });
    }

    payNowAction = () => {
        
        let requestData = {};
        requestData = this.state.orderData;
        requestData.voucher_ids = this.state.selectedVouchers;
        this.setState({payBtnLoading: true});

        ssoInstance.post(this.props.orderUrl, requestData).then((res) => {
            let pgData = res.data.data.pg;

            if(res.data.status === true && res.data.data.is_payment_required === true) {
                this.setState({pgData, createPayuForm: true, payBtnLoading:false});
                window.document.payuform.submit()
            } else if (res.data.status === true && res.data.data.is_payment_required === false) {
                window.location.href = "/?callback=1&status=1&message="+res.data.message;
            } else {
                window.location.href = "/?callback=1&status=false&message="+res.data.message;
            }
        }).catch((error) => {
            this.setState({payBtnLoading: false});
        });

    };

    renderPayUForm(){
        let pgData = this.state.pgData;
        return React.createElement('div', {className: "hide"},
            React.createElement('form', {method: 'post', action: pgData.url, ref: this.payuform, id: "payuform", name: "payuform"},

                React.createElement('input', {type: 'hidden', className: 'hide', name: 'key', defaultValue: pgData.key}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'txnid', defaultValue: pgData.txnid}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'amount', defaultValue: pgData.amount}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'productinfo', defaultValue: pgData.productinfo}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'firstname', defaultValue: pgData.firstname}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'email', defaultValue: pgData.email}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'phone', defaultValue: pgData.phone}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'service_provider', defaultValue: pgData.service_provider}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf1', defaultValue: pgData.udf1}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf2', defaultValue: pgData.udf2}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf3', defaultValue: pgData.udf3}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf4', defaultValue: pgData.udf4}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'udf5', defaultValue: pgData.udf5}),

                React.createElement('input', {type: 'hidden', className: 'hide', name: 'hash', defaultValue: pgData.hash}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'surl', defaultValue: pgData.surl}),
                React.createElement('input', {type: 'hidden', className: 'hide', name: 'furl', defaultValue: pgData.furl}),
            )
        );
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.renderComponents(this.state.loading, this.state.allowEdit,
                        this.state.data, this.state.allowed, this.state.paymentError, this.state.orderData, this.state.payBtnLoading)
                }
                {
                    this.state.createPayuForm ?
                        this.renderPayUForm()
                        : ""
                }
            </React.Fragment>
        );
    }
}

const TotalData = React.memo(function(props) {
    let totalRows = props.data.length;
    return (
        <React.Fragment>            
            <h3 className="bp3-text-xlarge heading mb-3 mt-4">Payment Summary</h3>
            <div className="payment-summary">
                {
                    props.data.map((single, key) => {

                        return <div key={key} className={ ((totalRows - 1) === key) ? "pay-sum-row total" : "pay-sum-row"}>
                            <div className="item">{single.title}</div>
                            <div className="price">
                                {key ? "₹ " : ""}
                                {single.value}</div>
                        </div>
                    })
                }
            </div>
            <div className="pay-btn row">
                <div className="col-md-6 col-sm-6 col-6 text-left">
                    <Button intent={Intent.WARNING} onClick={() => props.retry()}>Edit</Button>
                </div>
                <div className="col-md-6 col-sm-6 col-6 text-right">
                    <Button loading={props.payBtnLoading} intent={Intent.PRIMARY} onClick={() => props.payNow()}>Pay Now</Button>
                </div>
            </div>

            <div className="bp3-card bp3-elevation-0 col-12" style={{padding:'15px',marginTop:'20px'}}>
                <p>*Attention!</p><span>Do you want to make transaction with 0% payment gateway charge? contact our UPOS agent.</span>
            </div>
        </React.Fragment>
    );
});