import React from 'react';
import {RadioGroup, Radio, FormGroup, Intent, Button} from "@blueprintjs/core";
import Select from "react-select";
import config from "../../../core/Config";
import {ssoInstance} from "gg-react-utilities";
import {generateSessionId, getAllUrlParams, validateForm} from "../../../core/utils/Helper";
import {Auth} from "gg-react-utilities";
import * as _ from "lodash";

class Step1 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step: props.step,
            allowEdit: props.allowEdit ? props.allowEdit : true,
            rechargeType: 2,
            redirect: null,
            operatorOptions: [],
            isOperatorOptionsLoading: true,
            isCirclesOptionsLoading: true,
            operator: "",
            circles: "",
            number: props.mobile ? props.mobile : "",
            amount: null,
            account: "",
            amountError: false,
            amountErrorMessage: "",
            numberError: false,
            numberErrorMessage: "",
            operatorError: false,
            operatorErrorMessage: "",
            circlesError: false,
            circlesErrorMessage: "",
            circlesOptions: [],
            sendPlanId:true,
            circlesSelection:''
        };
    }
    componentDidMount(){

        let urlData = getAllUrlParams();
        let mobile = urlData.bill_no ? urlData.bill_no : null;
        let type = urlData.type ? parseInt(urlData.type, 10) : 2;
        let operator = urlData.operator ? parseInt(urlData.operator, 10) : null;
        let getOperator = true;
        if(mobile && type && operator){
            getOperator = false;
            this.setState({number:mobile, rechargeType: type, operator},
                () => {
                    this.getOperatorOptions(this.state.rechargeType);
                    this.getCircleOptions(operator);
            });
        }
        if(getOperator){
            this.getOperatorOptions(this.state.rechargeType);
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            allowEdit: nextProps.allowEdit,
            amount: nextProps.amount,
            rechargeType: nextProps.rechargeType,
            sendPlanId:true
        });
    }
    proceedPayment(e){

        if(!Auth.isLoggedIn()){
            Auth.login(true)
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        let fieldsToBeChecked = [
            {state: "operator", validation: ["required"]},
            {state: "circles", validation: ["required"]},
            {state: "number", validation: ["required", "length:10", "numeric"], message: ["Mobile No. is required.", "Mobile number must be 10 character long."]},
            {state: "amount", validation: ["required", "max:500000", "numeric"],message: ["Please Select Amount in Recharge Plan List."]},

        ];

        let canProceed =  validateForm(this, fieldsToBeChecked);

        if(canProceed){
            let validateData = {};
            validateData.number = this.state.number;
            validateData.type = this.state.rechargeType;
            validateData.session_id = generateSessionId();
            validateData.platform = "web";
            validateData.amount = this.state.amount;
            validateData.provider = this.state.operator;
            validateData.circle_id = this.state.circles;
            validateData.is_upi = 1;
            this.props.validateData(validateData,this.state.sendPlanId);
        }
        if(e !== false){
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        //return false;
    }
    getCircleOptions(operator){
        this.setState({isOperatorCirclesLoading: true});

        ssoInstance.get(config.GET_CIRCLES+"/"+operator).then((res) => {
            let circlesOptions = [];
            let circlesSelection = {};
            res.data.data.map((single) => {
                if(single.id === parseInt(this.state.operator, 10)){
                    circlesSelection = {value: single.id, label: single.circle_name};
                }
                circlesOptions.push({value: single.id, label: single.circle_name});
                return true;
            });
            this.setState({circlesOptions, isOperatorCirclesLoading: false,circlesSelection:''});
            if(!_.isEmpty(circlesSelection)) this.setState({circlesSelection});
        });
    }

    getOperatorOptions(rechargeType){
        this.setState({isOperatorOptionsLoading: true});
        let obj = {
            service_id : rechargeType,
            state_id : 0,
            city_id : 0
        }
        ssoInstance.post(config.GET_OPERATOR,obj).then((res) => {
            let operatorOptions = [];
            let operatorSelection = {};
            res.data.data.map((single) => {
                if(single.id === parseInt(this.state.operator, 10)){
                    operatorSelection = {value: single.id, label: single.service_provider_name};
                }
                operatorOptions.push({value: single.id, label: single.service_provider_name});
                return true;
            });
            this.setState({operatorOptions, isOperatorOptionsLoading: false});
            if(!_.isEmpty(operatorSelection)) this.setState({operatorSelection});
        });
    }
    onOperatorChange = (operator) => {

       /* if(!Auth.isLoggedIn()){
            Auth.login(true)
            return false;
        }
*/
        this.setState({ operator: operator.value, operatorSelection: operator, isOperatorCirclesLoading: true });

        this.getCircleOptions(operator.value);
    };
    onCirclesChange = (circles) =>{
        this.setState({ circles: circles.value, circlesSelection: circles});
        this.props.onOperatorChange(this.state.operator,circles.value);
    }
    onFieldChange(fieldName,event) {

        if(fieldName == 'amount'){
            this.setState({ sendPlanId:false });
            this.props.amountSet(event.target.value);
        }
        this.setState({ [fieldName]: event.target.value });
    }
    changeRechargeType(event){
        this.setState({
            rechargeType: parseInt(event.target.value, 10)
        },
            this.getOperatorOptions(parseInt(event.target.value, 10)),
            this.props.changeRechargeType(parseInt(event.target.value, 10))
        );
    }
    render(){
        return(
            <React.Fragment>
                <h3 className="bp3-text-xlarge heading mb-3 mt-2">Mobile Prepaid
                    <div className="data-valid">
                        {
                            /*this.state.allowedPayment ? <span className="correct"><CheckRounded style={{fontSize: '14'}}/></span>
                                : <span className="in-correct"><ClearRounded style={{fontSize: '15'}}/></span>*/
                        }
                    </div>
                </h3>
                <form className="form-data pt-0">
                    {/*<RadioGroup
                        className="d-inline mobile-type d-flex"
                        onChange={(e) => this.changeRechargeType(e)}
                        name="type"
                        disabled={this.state.allowEdit === false}
                        selectedValue={this.state.rechargeType}
                    >
                        <Radio label="Prepaid" value={2} />
                        <Radio label="Postpaid" value={1} />
                    </RadioGroup>*/}
                    <FormGroup
                        helperText={this.state.operatorErrorMessage}
                        intent={this.state.operatorError ? Intent.DANGER : Intent.NONE}
                    >
                        <Select
                            className="react-select"
                            placeholder="Select Operator"
                            isDisabled={this.state.allowEdit === false}
                            style={{marginTop: "20px"}}
                            value={this.state.operatorSelection}
                            onChange={this.onOperatorChange}
                            isLoading={this.state.isOperatorOptionsLoading}
                            options={this.state.operatorOptions}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.circlesErrorMessage}
                        intent={this.state.circlesError ? Intent.DANGER : Intent.NONE}
                    >
                        <Select
                            className="react-select"
                            placeholder="Select Circle"
                            isDisabled={this.state.allowEdit === false}
                            style={{marginTop: "20px"}}
                            value={this.state.circlesSelection}
                            onChange={this.onCirclesChange}
                            isLoading={this.state.isOperatorCirclesLoading}
                            options={this.state.circlesOptions}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.numberErrorMessage}
                        intent={this.state.numberError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.number}
                                intent={this.state.numberError ? Intent.DANGER : Intent.NONE}
                                onChange={(e)=>{this.onFieldChange('number',e)}}
                                required
                        />
                        <label className="form-control-placeholder">Enter Mobile No</label>
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.amountErrorMessage}
                        intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                type="number"
                                min={"1"}
                                required
                                disabled={true}
                                value={this.state.amount}
                                intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                                //onChange={(e)=>{this.onFieldChange('amount',e)}}
                        />
                        <label className="form-control-placeholder">Enter Amount</label>
                    </FormGroup>
                    {
                        this.state.allowEdit ?
                            <div className="proceed-btn">
                                <Button type="submit" intent={Intent.PRIMARY} onClick={(e) => this.proceedPayment(e)}>Proceed</Button>
                            </div>
                            : ""
                    }

                </form>
            </React.Fragment>
        );
    }
}

export default Step1