import React from 'react';
import {NavLink} from "react-router-dom";
import KeyboardArrowRightRounded from '@material-ui/icons/KeyboardArrowRightRounded';
import Mobilegray from '../../core/images/svg/Mobilegray.svg';
import Mobileblue from '../../core/images/svg/Mobileblue.svg';
import Landlinegray from '../../core/images/svg/Landlinegray.svg';
import Landlineblue from '../../core/images/svg/Landlineblue.svg';
import Electricitygray from '../../core/images/svg/Electricitygray.svg';
import Electricityblue from '../../core/images/svg/Electricityblue.svg';
import Gasgray from '../../core/images/svg/Gasgray.svg';
import Gasblue from '../../core/images/svg/Gasblue.svg';
import Broadbandgray from '../../core/images/svg/Broadbandgray.svg';
import Broadbandblue from '../../core/images/svg/Broadbandblue.svg';
import Dthgray from '../../core/images/svg/Dthgray.svg';
import Dthblue from '../../core/images/svg/Dthblue.svg';
import Datagray from '../../core/images/svg/Datagray.svg';
import Datablue from '../../core/images/svg/Datablue.svg';
import Watergray from '../../core/images/svg/Watergray.svg';
import Waterblue from '../../core/images/svg/Waterblue.svg';
import {Button, Icon, Intent} from "@blueprintjs/core";
import {ssoInstance} from "gg-react-utilities";
import config from "../../core/Config";

export default class NavBar extends React.Component{
	constructor(props){
		super(props);

		this.state = {
			active: false,
			allServices:[],
			selectedServices:{},
			serviceId: this.props.match.params.serviceId ? this.props.match.params.serviceId : ''
		}
	}

	componentDidMount() {
		ssoInstance.get(config.API_URL+"services")
			.then((res) => {
				let source = res.data;
				if(source.status){
					if(this.state.serviceId == '') {
						this.setState({
							allServices: source.data,
							selectedServices: this.props.isStatic ? {} : source.data[0]
						}, () => {
							if (!this.props.isStatic) {
								this.props.selectedServices(this.state.selectedServices);
							}
						})
					}else{
						let selectedServices = source.data.filter(item => {
							return item.service_id == this.state.serviceId;
						});
						this.setState({
							allServices: source.data,
							selectedServices: selectedServices[0]
						}, () => {
							if (!this.props.isStatic) {
								this.props.selectedServices(this.state.selectedServices);
							}
						})
					}
				}
			})
			.catch((error) => {

			});
	}

	selectService = (service) =>{
		if(!this.props.isStatic) {
			this.props.selectedServices(service);
			this.setState({selectedServices: service});
		}
		this.props.history.push('/' + service.service_id);
	}

	recharge_menu	(){
		this.setState({
			active: !this.state.active
		})
	}

    render(){
        const arrowStyle = {fontSize: '15px'};
        return(
        	<React.Fragment>
				<div className="recharge_menu">
					<div className="nav" style={{overflow: "hidden",overflowY: "scroll"}}>
						{
							this.state.allServices && this.state.allServices.length ?
								this.state.allServices.map((list,key)=>{
									if(list.service_id == 2){
										return (
											<NavLink className="nav-bar-item" exact activeClassName="active" to="/mobile">
												<div className="col-sm-12 col-12 pr-sm-0 pr-md-3">
													<div className="row rec align-items-center d-flex justify-content-center">
														<div className="col-sm-2 col-2 left-xs">
															<div className="icon">
																<img className="active" src={Mobileblue} alt="active-icon" />
																<img className="non-active" src={Mobilegray} alt="active-icon" />
															</div>
														</div>
														<div className="col-sm-10 col-10 d-flex justify-content-between align-items-center pr-2">
															<p className="nav-name">Mobile Prepaid</p>
															<div className="nav-right"><KeyboardArrowRightRounded style={arrowStyle}/></div>
														</div>
													</div>
												</div>
											</NavLink>
										)
									}else {
										return (
											<a aria-current="page"
											   className={list.service_id == this.state.selectedServices.service_id ? "nav-bar-item active" : "nav-bar-item"}
											   onClick={() => {
												   this.selectService(list)
											   }}>
												<div className="col-sm-12 col-12 pr-sm-0 pr-md-3">
													<div
														className="row rec align-items-center d-flex justify-content-center">
														<div className="col-sm-2 col-2 left-xs">
															<div className="icon">
																<img className="active" src={list.service_img}
																	 alt="active-icon"/>
																<img className="non-active"
																	 src={list.service_unselect_img} alt="active-icon"/>
															</div>
														</div>
														<div
															className="col-sm-10 col-10 d-flex justify-content-between align-items-center pr-2">
															<p className="nav-name">{list.service_type}</p>
															<div className="nav-right"><KeyboardArrowRightRounded
																style={arrowStyle}/>
															</div>
														</div>
													</div>
												</div>
											</a>
										)
									}
								})
							:''
						}
					</div>
				</div>
			</React.Fragment>
        );
    }
}