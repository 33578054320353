import React from 'react';
import Loading from "./Plans/Loading";
import SingleRow from "./OldRecharges/SingleRow";
import {Auth, ssoInstance} from 'gg-react-utilities';
import config from "../../core/Config";
import NoLogin from "../../core/components/NoLogin";


export default class OldRecharges extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            bills: [],
            nextPageUrl: null,
            isLoggedIn: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.authCheck(nextProps.services.service_id);
    }

    async authCheck(serviceId){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn:res},()=>{
                    if(this.state.isLoggedIn===true){
                        this.getOrderListFromApi(config.GET_ORDER_LIST,serviceId, true);
                    }
                })
            });
        },1)
    }
    getOrderListFromApi(url, serviceId, onMount = false){
        if(this.state.isLoggedIn) {
            ssoInstance.post(url, {service_id: serviceId, per_page: 5})
                .then((res) => {
                    if (onMount) {
                        this.setState({loadingData: false,bills: res.data.data.data, nextPageUrl: res.data.data.next_page_url});
                    }else{
                        let newData = this.state.bills.concat(res.data.data.data);
                        this.setState({bills: newData, nextPageUrl: res.data.data.next_page_url});
                    }
                })
                .catch();
        }
    }
    showMoreContent(){
        this.getOrderListFromApi(this.state.nextPageUrl,this.props.serviceId, false);
    }
    renderComponents(){
        if(this.state.isLoggedIn){
            if(this.state.loadingData){
                return <Loading />;
            }
            else{
                if(this.state.bills.length){
                    return (
                        <React.Fragment>
                            <table className="bp3-html-table">
                                <tbody>
                                {
                                    this.state.bills.map((bill, key) => {
                                        return <SingleRow setRepeatData={(operator, amount) => this.props.setRepeatData(operator, amount)} data={bill} key={key}/>
                                    })
                                }
                                </tbody>
                            </table>
                            {
                                this.state.nextPageUrl ?
                                    <span className="text-center bill-all" onClick={() => this.showMoreContent()} style={{cursor: 'pointer'}}>Show More</span>
                                    : ""
                            }
                        </React.Fragment>
                    );
                }
                else{
                    return (<div className="text-center pb-3">{this.props.services.service_type} Payment Not Found.</div>);
                }
            }
        }
        else{
            return (<NoLogin />);
        }
    }
    render(){
        return(
            <React.Fragment>
                <div className="border bills py-3">
                    <h3 className="bp3-text-xlarge heading mt-3 mb-3 pl-s-3">Recently Paid {this.props.services.service_type} Payment</h3>
                    {
                        this.renderComponents()
                    }
                </div>
            </React.Fragment>
        );
    }
}