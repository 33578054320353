import React from 'react';
import LoadingRecentBills from "./LoadingRecentBills";
import RecentBillSingle from "./RecentBills/RecentBillSingle";
import {Auth, ssoInstance} from 'gg-react-utilities';
import config from "../../core/Config";
import NoLogin from '../../core/components/NoLogin';

export default class RecentBills extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            bills: [],
            nextPageUrl: null
        }
    }
    componentDidMount(){
        this.authCheck();
    }
    async authCheck(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn:res},()=>{
                    if(this.state.isLoggedIn===true){
                        this.getOrderListFromApi(config.GET_ORDER_LIST, true);
                    }
                })
            });
        },1)
    }
    getOrderListFromApi(url, onMount = false){
        if(this.state.isLoggedIn) {
            ssoInstance.post(url, {service_id: this.props.serviceId, per_page: 5})
                .then((res) => {
                    let newData = this.state.bills.concat(res.data.data.data);
                    this.setState({bills: newData, nextPageUrl: res.data.data.next_page_url});
                    if (onMount) {
                        this.setState({loadingData: false});
                    }
                })
                .catch();
        }
    }
    showMoreContent(){
        this.getOrderListFromApi(this.state.nextPageUrl, false);
    }
    renderComponents(){

        if(this.state.isLoggedIn){
            if(this.state.loadingData){
                return <LoadingRecentBills />;
            }
            else{
                if(this.state.bills.length){
                    return (
                        <React.Fragment>
                            <table className="bp3-html-table">
                                <tbody>
                                    {
                                        this.state.bills.map((bill, key) => {
                                            return <RecentBillSingle data={bill} key={key}/>
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                this.state.nextPageUrl ?
                                    <span className="text-center bill-all" onClick={() => this.showMoreContent()} style={{cursor: 'pointer'}}>Show More</span>
                                    : ""
                            }

                        </React.Fragment>
                    );
                }
                else{
                    return (<div className="text-center pb-3">No Recent Bill Found.</div>);
                }
            }
        }
        else{
            return (<NoLogin />);
        }
    }
    render(){
        return(
            <div className="bills pb-3 mt-s-4">
                <h3 className="bp3-text-xlarge heading mt-3 mb-3 pl-s-3">{this.props.title}</h3>
                {
                    this.renderComponents()
                }
            </div>
        );
    }
}