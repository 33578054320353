import React from 'react';
import {Intent, FormGroup, Button} from "@blueprintjs/core";
import CheckRounded from '@material-ui/icons/CheckRounded';
import config from "../../../core/Config";
import {generateSessionId, validateForm} from "../../../core/utils/Helper";
import {ssoInstance} from "gg-react-utilities";
import Select from 'react-select';

export default class Step1 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step: props.step,
            allowEdit: props.allowEdit ? props.allowEdit : true,
            redirect: null,
            operator: null,
            operatorOptions: [],
            isOperatorOptionsLoading: true,
            mobile: null,
            amount: null,
            account: "",
            authenticator: "",
            amountError: false,
            amountErrorMessage: "",
            numberError: false,
            numberErrorMessage: "",
            operatorError: false,
            operatorErrorMessage: ""
        };
    }
    componentDidMount(){
        this.getOperatorOptions(config.TYPE_WATER);
    }
    componentWillReceiveProps(nextProps){
        this.setState({allowEdit: nextProps.allowEdit, amount: nextProps.amount});
    }
    getOperatorOptions(typeId){
        this.setState({isOperatorOptionsLoading: true});

        let obj = {
            provider : typeId
        }
        ssoInstance.post(config.GET_OPERATOR, obj).then((res) => {
            let operatorOptions = [];
            res.data.data.map((single) => {
                operatorOptions.push({value: single.id, label: single.service_provider_name});
                return true;
            });
            this.setState({operatorOptions, isOperatorOptionsLoading: false});
        });
    }
    onFieldChange(fieldName) {
        return function (event) {
            this.setState({ [fieldName]: event.target.value });
        }
    }
    onOperatorChange = (operator) => {
        this.setState({ operator: operator.value, operatorSelection: operator });
    };
    renderHeader(){
        if(this.state.step === 1){
            return(
                <React.Fragment>
                    <h3 className="bp3-text-xlarge heading mt-2 mb-2">Pay your Water Bill</h3>
                    <h6 className="bp3-text-small heading mt-1">Just give us a few details</h6>
                </React.Fragment>
            );
        }
        if(this.state.step === 2){
            return (
                <React.Fragment>
                    <h1 className="bp3-text-xlarge heading mb-3">Pay your Datacard Bill
                        <div className="data-valid">
                            <span className="correct"><CheckRounded style={{fontSize: '14'}}/></span>
                        </div>
                    </h1>
                </React.Fragment>
            );
        }
    }
    proceedPayment(e){
        let fieldsToBeChecked = [
            {state: "operator", validation: ["required"]},
            {state: "amount", validation: ["required", "numeric"]},
            {state: "number", validation: ["required", "numeric"], message: ["Bill No. is required."]},
        ];
        let canProceed =  validateForm(this, fieldsToBeChecked);

        if(canProceed){
            let validateData = {};
            validateData.number = this.state.number;
            validateData.type = config.TYPE_WATER;
            validateData.session_id = generateSessionId();
            validateData.amount = this.state.amount;
            validateData.provider = this.state.operator;

            this.props.validateData(validateData);
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
    render(){
        return(
            <React.Fragment>
                {
                    this.renderHeader()
                }
                <form className="form-data">
                    <FormGroup
                        helperText={this.state.operatorErrorMessage}
                        intent={this.state.operatorError ? Intent.DANGER : Intent.NONE}
                    >
                        <Select
                            className="react-select"
                            placeholder="Select Operator"
                            style={{marginTop: "20px"}}
                            value={this.state.operatorSelection}
                            onChange={this.onOperatorChange}
                            isDisabled={this.state.allowEdit === false}
                            isLoading={this.state.isOperatorOptionsLoading}
                            options={this.state.operatorOptions}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.numberErrorMessage}
                        intent={this.state.numberError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.number}
                                intent={this.state.numberError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('number').bind(this)}
                        />
                        <label className="form-control-placeholder">Bill Number</label>
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.amountErrorMessage}
                        intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.amount}
                                type="number"
                                intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('amount').bind(this)}
                        />
                        <label className="form-control-placeholder">Enter Amount</label>
                    </FormGroup>
                    {
                        this.state.allowEdit ?
                            <div className="proceed-btn">
                                <Button type="submit" intent={Intent.PRIMARY} onClick={(e) => this.proceedPayment(e)}>Proceed</Button>
                            </div>
                            : ""
                    }
                </form>
            </React.Fragment>
        );
    }
}