import React from 'react';
import BuildRounded from '@material-ui/icons/BuildRounded';

export default class Maintenance extends React.Component{
    render(){
        return(
            <div className="maintenance">
                <div className="icon z-depth-2"><BuildRounded style={{fontSize: 52, marginTop: "20px"}}/></div>
                <div className="message">Sorry, we're down for maintenance.</div>
                <div className="sub-message">We'll be back shortly.</div>
            </div>
        );
    }
}