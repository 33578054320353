import {required, length, max, min, numeric} from "./Validate";
import * as _ from "lodash";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const parseDateTime = (dateTime) => {
    let d = new Date(dateTime.split('-').join('/'));
    let response = {
        date: d.getDate(),
        monthName: monthNames[d.getMonth()],
        year: d.getFullYear(),
        timeInAMPM: d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    };
    return response;
};
export const getAllUrlParams = (url) => {

    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    let obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        let arr = queryString.split('&');

        for (let i=0; i<arr.length; i++) {
            // separate the keys and the values
            let a = arr[i].split('=');

            // in case params look like: list[]=thing1&list[]=thing2
            let paramNum = undefined;
            let paramName = a[0].replace(/\[\d*\]/, function(v) {
                paramNum = v.slice(1,-1);
                return '';
            });

            // set parameter value (use 'true' if empty)
            let paramValue = typeof(a[1])==='undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            paramValue = paramValue.toLowerCase();

            // if parameter name already exists
            if (obj[paramName]) {
                // convert value to array (if still string)
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                // if no array index number specified...
                if (typeof paramNum === 'undefined') {
                    // put the value on the end of the array
                    obj[paramName].push(paramValue);
                }
                // if array index number specified...
                else {
                    // put the value at that index number
                    obj[paramName][paramNum] = paramValue;
                }
            }
            // if param name doesn't exist yet, set it
            else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
};

export const generateSessionId = () =>{
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 15; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};

export const validateForm = (obj, fieldsConfig) =>{
    let canProceed = true;
    fieldsConfig.map((field) => {
        let fieldNameInCamel = _.camelCase(field.state);
        let value = obj.state[field.state];
        let currentErrorFound = false;
        field.validation.map((validate, index) => {
            let fieldName = String([field.state]).replace("_", " ");
            fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

            let validateAttr = validate.split(":");
            validate = validateAttr[0];
            let param = validateAttr[1];
            if(validate === "required"){
                let valid = required(fieldName, value);
                if(valid !== true){
                    canProceed = false;
                    let message = valid;
                    if(typeof field.message !== "undefined"){
                        message = field.message[index];
                    }
                    let updateStates = {[fieldNameInCamel+"Error"]: true, [fieldNameInCamel+"ErrorMessage"]: message};
                    obj.setState(updateStates);
                    currentErrorFound = true;
                }else{
                    let updateStates = {[fieldNameInCamel+"Error"]: false, [fieldNameInCamel+"ErrorMessage"]: ""};
                    if(!currentErrorFound) obj.setState(updateStates);
                }
            }
            if(validate === "length"){
                let valid = length(fieldName, value, param);
                if(valid !== true){
                    canProceed = false;
                    let message = valid;
                    if(typeof field.message !== "undefined"){
                        if(typeof field.message[index] !== "undefined") message = field.message[index];
                    }
                    let updateStates = {[fieldNameInCamel+"Error"]: true, [fieldNameInCamel+"ErrorMessage"]: message};
                    obj.setState(updateStates);
                    currentErrorFound = true;
                }else{
                    let updateStates = {[fieldNameInCamel+"Error"]: false, [fieldNameInCamel+"ErrorMessage"]: ""};
                    if(!currentErrorFound)  obj.setState(updateStates);
                }
            }
            if(validate === "max"){
                let valid = max(fieldName, value, param);
                if(valid !== true){
                    canProceed = false;
                    let message = valid;
                    if(typeof field.message !== "undefined"){
                        if(typeof field.message[index] !== "undefined") message = field.message[index];
                    }
                    let updateStates = {[fieldNameInCamel+"Error"]: true, [fieldNameInCamel+"ErrorMessage"]: message};
                    obj.setState(updateStates);
                    currentErrorFound = true;
                }else{
                    let updateStates = {[fieldNameInCamel+"Error"]: false, [fieldNameInCamel+"ErrorMessage"]: ""};
                    if(!currentErrorFound)  obj.setState(updateStates);
                }
            }if(validate === "min"){
                let valid = min(fieldName, value, param);
                if(valid !== true){
                    canProceed = false;
                    let message = valid;
                    if(typeof field.message !== "undefined"){
                        if(typeof field.message[index] !== "undefined") message = field.message[index];
                    }
                    let updateStates = {[fieldNameInCamel+"Error"]: true, [fieldNameInCamel+"ErrorMessage"]: message};
                    obj.setState(updateStates);
                    currentErrorFound = true;
                }else{
                    let updateStates = {[fieldNameInCamel+"Error"]: false, [fieldNameInCamel+"ErrorMessage"]: ""};
                    if(!currentErrorFound)  obj.setState(updateStates);
                }
            }
            if(validate === "numeric"){
                let valid = numeric(fieldName, value);
                if(valid !== true){
                    canProceed = false;
                    let message = valid;
                    if(typeof field.message !== "undefined"){
                        if(typeof field.message[index] !== "undefined") message = field.message[index];
                    }
                    let updateStates = {[fieldNameInCamel+"Error"]: true, [fieldNameInCamel+"ErrorMessage"]: message};
                    obj.setState(updateStates);
                    currentErrorFound = true;
                }else{
                    let updateStates = {[fieldNameInCamel+"Error"]: false, [fieldNameInCamel+"ErrorMessage"]: ""};
                    if(!currentErrorFound) obj.setState(updateStates);
                }
            }
            if(validate === "regex"){
                let valid = min(fieldName, value, param);
                if(valid !== true){
                    canProceed = false;
                    let message = valid;
                    if(typeof field.message !== "undefined"){
                        if(typeof field.message[index] !== "undefined") message = field.message[index];
                    }
                    let updateStates = {[fieldNameInCamel+"Error"]: true, [fieldNameInCamel+"ErrorMessage"]: message};
                    obj.setState(updateStates);
                    currentErrorFound = true;
                }else{
                    let updateStates = {[fieldNameInCamel+"Error"]: false, [fieldNameInCamel+"ErrorMessage"]: ""};
                    if(!currentErrorFound)  obj.setState(updateStates);
                }
            }
            return true;
        });
        return true;
    });
    return canProceed;
};