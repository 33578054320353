import React from 'react';
import DoneRounded from "@material-ui/icons/DoneRounded";
import ClearRounded from "@material-ui/icons/ClearRounded";

export default class OrderCallback extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            status: props.status
        };
    }
    componentDidMount(){
        setTimeout( () => {
            //this.props.hideCallback();
            window.history.pushState({},"", "?");
            window.location.reload();
        }, 5000);
    }
    render(){
        return(
            <div className="border recharge-mobile">
                <div className="order-status">
                    <div className="icon-box">
                        <div className={this.state.status ? "icon-type success" : "icon-type failure"}>
                            {
                                this.state.status ?
                                    <div className="icon-text success">
                                        <DoneRounded style={{fontSize: 49}} />
                                    </div>
                                    :
                                    <div className="icon-text failure">
                                        <ClearRounded style={{fontSize: 49}}/>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="status-text">
                        {
                            this.state.status ?
                                <React.Fragment>
                                Congratulation!! <br />
                                Your Recharge Successfully Done.
                                </React.Fragment>
                                :
                                <React.Fragment>
                                Sorry!! <br />
                                Your Recharge Payment Failed.
                                </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        );
    }
}