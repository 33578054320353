import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {PackageConfigure} from 'gg-react-utilities';
import {envSetting} from "./core/Config";
import {unregister as unregisterServiceWorker} from './registerServiceWorker';

ReactDOM.render(
    <React.Fragment>
        <PackageConfigure {...envSetting} />
        <App/>
    </React.Fragment>

    , document.getElementById('root'));
unregisterServiceWorker();
