import React from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import { withRouter } from 'react-router-dom';
import BBP from '../../core/images/svg/bharat-bill-pay.svg';

class TopBar extends React.Component {
    goBack(){
        this.props.history.goBack();
    }
    render(){
        return(
            <div className="top-bar d-flex align-items-center row">
                    <div className="col-md-9 d-flex justify-content-md-start">
                        <span className="back-icon" onClick={() => this.goBack()}><KeyboardArrowLeftRounded /></span>
                        <h1 className="title m-0">{this.props.title}</h1>
                    </div>
                    <div className="col-md-3 d-flex justify-content-md-end">
                        <img
                            style={
                                {height: "45px"}
                            }
                            src={BBP} />
                    </div>
            </div>
        );
    }
}
export default withRouter(TopBar);