import React from 'react';
import MobilePaySteps from "./components/MobilePaySteps";

export default class Index extends React.Component {
    render(){
        return(
            <div className="container pb-5">
                    <MobilePaySteps {...this.props} />
            </div>
        );
    }
}