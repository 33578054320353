
export const envSetting = {
    APP_URL: process.env.REACT_APP_APP_URL,
    API_URL:process.env.REACT_APP_API_URL,
    ACCOUNT_API_URL: process.env.REACT_APP_ACCOUNT_API_URL,
    STORE_API_URL: process.env.REACT_APP_STORE_API_URL,
    MEGABRAND_API_URL: process.env.REACT_APP_MEGABRAND_API_URL,
    APP_ENV: process.env.REACT_APP_APP_ENV,
    Extension: process.env.REACT_APP_Extension,
};
const config = {
    IS_MAINTENANCE_MODE: false,
    APP_URL: envSetting.APP_URL,

    TYPE_LANDLINE : 3,
    TYPE_ELECTRICITY : 4,
    TYPE_GAS : 5,
    TYPE_BROADBAND : 7,
    TYPE_DTH : 9,
    TYPE_DATACARD : 10,
    TYPE_WATER : 11,

    API_URL: envSetting.API_URL,
    GET_ORDER_LIST: envSetting.API_URL+"orders",
    GET_OPERATOR: envSetting.API_URL+"service_provider",
    GET_CIRCLES: envSetting.API_URL+"mobile-prepaid/telecom-circles",
    GET_CITYLIST: envSetting.API_URL+"electricity/citylist",
    GET_RECHARGE_PLAN: envSetting.API_URL+"get_recharge_plan",


    VALIDATE_VOUCHER: envSetting.API_URL+"validate-vouchers",
    REMOVE_VOUCHER: envSetting.API_URL+"validate",

    VALIDATE_PREPAID_VOUCHER_RECHARGE: envSetting.API_URL+"mobile-prepaid/validate-vouchers",
    REMOVE_PREPAID_VOUCHER_RECHARGE: envSetting.API_URL+"mobile-prepaid/validate",

    VALIDATE_VOUCHER_LANDLINE: envSetting.API_URL+"landline/validate-vouchers",
    VALIDATE_VOUCHER_ELECTRICITY: envSetting.API_URL+"electricity/validate-vouchers",
    VALIDATE_VOUCHER_GAS: envSetting.API_URL+"gas/validate-vouchers",
    VALIDATE_VOUCHER_BROADBAND: envSetting.API_URL+"broadband/validate-vouchers",
    VALIDATE_VOUCHER_DTH: envSetting.API_URL+"dth/validate-vouchers",
    VALIDATE_VOUCHER_DATACARD: envSetting.API_URL+"datacard/validate-vouchers",
    VALIDATE_VOUCHER_WATER: envSetting.API_URL+"water/validate-vouchers",

    ORDER_RECHARGE: envSetting.API_URL+"broadband/order",
    ORDER_LANDLINE: envSetting.API_URL+"landline/order",
    ORDER_ELECTRICITY: envSetting.API_URL+"electricity/order",
    ORDER_GAS: envSetting.API_URL+"gas/order",
    ORDER_BROADBAND: envSetting.API_URL+"broadband/order",
    ORDER_DTH: envSetting.API_URL+"dth/order",
    ORDER_DATACARD: envSetting.API_URL+"datacard/order",
    ORDER_WATER: envSetting.API_URL+"water/order",

    GET_PDF: envSetting.API_URL+"get_pdf"
};
export default config;
