export const required = (fieldName, value) =>{
    if(!value){
        return fieldName+" is required.";
    }
    return true;
};
export const length = (fieldName, value, checker) =>{
    if(parseInt(value.length, 10) !== parseInt(checker, 10)){
        return fieldName+" should be "+checker+" character long.";
    }
    return true;
};
export const max = (fieldName, value, checker) =>{
    if(Number(value) > Number(checker)){
        return fieldName+" should be less than "+checker+".";
    }
    return true;
};
export const min = (fieldName, value, checker) =>{
    if(Number(value) < Number(checker)){
        return fieldName+" should be greater than "+checker+".";
    }
    return true;
};
export const numeric = (fieldName, value) =>{
    if(isNaN(value)){
        return "Not a valid number (must not include spaces or special characters).";
    }
    return true;
};
